'use client';
import React, { useMemo } from 'react';
import { ArrowRightCircle } from 'iconoir-react';
import { SmartOrderAnalysisError } from '@/lib/types/smart-orders';
import { useSmartOrdersContext } from '@context/smart-orders-context';
import { uniq } from 'lodash';
import { useSellerAdminSettings } from '@/lib/hooks/use-settings';
import { useCatalogTranslation } from '@/lib/hooks/use-catalog-translations';

type SmartOrderErrorProps = {
  errors?: SmartOrderAnalysisError[];
};
const SmartOrderError = ({ errors }: SmartOrderErrorProps) => {
  const { getErrorMessage } = useSmartOrdersContext();
  const { SmartOrderSettings } = useSellerAdminSettings();
  const { ct } = useCatalogTranslation('admin_orders');
  const errorMessages = useMemo(() => {
    return uniq(
      errors
        ?.filter((error) => error.level !== 'sent')
        .map((error) => getErrorMessage(error.type)),
    );
  }, [errors, SmartOrderSettings]);
  if (errorMessages.length === 0) {
    return null;
  }
  return (
    <div
      data-testid="smart-order-error"
      className="text-secondary-700 text-xs font-semibold flex items-center gap-1"
    >
      <ArrowRightCircle className="w-4 h-4" />
      {errorMessages.length > 1 ? (
        <span>
          {ct('SmartOrders.tasksToComplete', '{{count}} tâches à réaliser', {
            count: errorMessages.length,
          })}
        </span>
      ) : (
        <span>{errorMessages[0]}</span>
      )}
    </div>
  );
};

export default SmartOrderError;
