import { useEffect, useState } from 'react';
import { useAccount } from '@context/account-context';
import { useTenant } from '@context/tenant-context';
import { usePathname } from 'next/navigation';

export const useFeedbackLink = (displayId?: string | null) => {
  const [link, setLink] = useState<string | null>(null);
  const { tenantId } = useTenant();
  const { ...loggedUser } = useAccount();
  const pathname = usePathname();

  useEffect(() => {
    if (pathname.includes('/orders/order_')) {
      setLink(
        `https://tally.so/r/wbKEg2?email=${loggedUser.email}&seller=${tenantId}&smartorder_id=${displayId}`,
      );
    } else {
      setLink(
        `https://tally.so/r/wbKEg2?email=${loggedUser.email}&seller=${tenantId}`,
      );
    }
  }, [pathname, tenantId, loggedUser.email, displayId]);

  return link;
};
