'use client';

import { useCatalogTranslation } from '@hooks/use-catalog-translations';
import Modal from '@components/common/modal';
import { Xmark } from 'iconoir-react';
import SmartOrderCard from '../../card/smart-orders-card';
import { SmartOrder } from '@/lib/types/smart-orders';

type DesyncModalProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  desyncOrders?: SmartOrder[];
};

const DesyncModal = ({ open, setOpen, desyncOrders }: DesyncModalProps) => {
  const { ct } = useCatalogTranslation('admin_homepage');
  const desyncOrdersCount = desyncOrders?.length ?? 0;

  return (
    <Modal open={open} setOpen={setOpen}>
      <div className="flex flex-col gap-1 max-h-[80vh]">
        <div className="flex justify-between">
          <strong>
            {ct(
              'Homepage.desyncModalTitle',
              'Désynchronisation de {{count}} commandes',
              {
                count: desyncOrdersCount,
              },
            )}
          </strong>
          <Xmark
            className="h-5 w-5 cursor-pointer"
            onClick={() => setOpen(false)}
          />
        </div>
        <p>
          {ct(
            'Homepage.desyncModal',
            "{{count}} commandes n'ont pas pu être enregistrées dans votre ERP. L'équipe technique a été alertée.",
            {
              count: desyncOrdersCount,
            },
          )}
        </p>
        <div className="overflow-y-auto flex flex-col gap-3">
          {desyncOrders?.map((order) => (
            <SmartOrderCard
              key={order.id}
              smartOrder={order}
              displayErrors={true}
              origin="desyncmodal"
            />
          ))}
        </div>
      </div>
    </Modal>
  );
};

export default DesyncModal;
