import { AnimatePresence, motion } from 'framer-motion';
import React, { ReactNode } from 'react';

type AnimatedListProps<T> = {
  items: T[];
  getKey: (item: T) => string;
  renderItem: (item: T) => ReactNode;
  className?: string;
  onItemClick?: (item: T) => void;
};

type AnimatedListItemProps = {
  children: ReactNode;
  onClick?: () => void;
};

const AnimatedListItem = ({ children, onClick }: AnimatedListItemProps) => {
  return (
    <motion.div
      layout
      initial={{ opacity: 0, y: -10 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, scale: 0.95 }}
      transition={{
        duration: 0.2,
        ease: [0.32, 0.72, 0, 1],
        layout: { duration: 0.2 },
      }}
      onClick={onClick}
      className={onClick ? 'cursor-pointer' : undefined}
    >
      {children}
    </motion.div>
  );
};

function AnimatedList<T>({
  items,
  getKey,
  renderItem,
  className,
  onItemClick,
}: AnimatedListProps<T>) {
  return (
    <div className={className}>
      <AnimatePresence mode="popLayout">
        {items?.map((item) => (
          <div key={getKey(item)}>
            <AnimatedListItem
              onClick={onItemClick ? () => onItemClick(item) : undefined}
            >
              {renderItem(item)}
            </AnimatedListItem>
          </div>
        ))}
      </AnimatePresence>
    </div>
  );
}

export default AnimatedList;
