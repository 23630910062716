'use client';

import React, { useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { format } from 'date-fns';
import { useSmartOrdersContext } from '@context/smart-orders-context';
import { useCatalogTranslation } from '@hooks/use-catalog-translations';
import { useEmailTruncation } from '@hooks/use-email-truncation';
import Button from '../../common/button';
import SmartOrderError from './smart-order-error';
import SmartOrderDebug from './smart-order-debug';
import { Tooltip } from 'react-tooltip';
import { DraftOrderTypeEnum, SmartOrder } from '@/lib/types/smart-orders';
import Link from 'next/link';
import { WarningTriangle } from 'iconoir-react';
import { useAmplitudeContext } from '@/lib/context/analytics-context';
import { SmartOrderStatus } from '@startcatalog/catalog-types-package';
import { useAccount } from '@/lib/context/account-context';
import { useTimeBasedMessage } from '@/lib/hooks/use-time-based-message';
import SmartOrderOpportunities from './smart-order-opportunities';

type SmartOrderCardProps = {
  smartOrder: SmartOrder;
  displayCta?: boolean;
  displayErrors?: boolean;
  displayOpportunities?: boolean;
  highlight?: boolean;
  origin?: 'desyncmodal' | 'kanban';
};
const SmartOrderCard = ({
  smartOrder,
  displayCta,
  displayErrors,
  displayOpportunities,
  highlight,
  origin = 'kanban',
}: SmartOrderCardProps) => {
  const { ct } = useCatalogTranslation(['admin_orders', 'admin_common']);
  const { trackAmplitudeEvent } = useAmplitudeContext();
  const { isDebugModeActive } = useAccount();
  const {
    getCtaLabel,
    handleClickOnCard: handleClickOnButton,
    handleUpdateSmartOrder,
  } = useSmartOrdersContext();
  const formattedDate = (date: Date) => {
    return format(new Date(date), 'dd/MM/yyyy, HH:mm');
  };

  const basedDate = useMemo(() => smartOrder.createdAt, [smartOrder.createdAt]);
  const { message, opacity } = useTimeBasedMessage(
    [
      {
        message: ct(
          'SmartOrders.ReadingOrder',
          "Lecture d'une commande en cours",
        ),
        threshold: 0,
      },
      {
        message: ct(
          'SmartOrders.InputtingInformation',
          'Saisie des informations en cours',
        ),
        threshold: 20000,
      },
    ],
    basedDate,
  );
  const setIsTest = (isTest: boolean) => {
    smartOrder.debugInfo!.isTest = isTest;
    handleUpdateSmartOrder(
      {
        metadata: {
          is_smart_order_test: isTest,
        },
      },
      smartOrder.id,
    );
  };

  const { containerRef, emailRef, formattedEmail } = useEmailTruncation(
    !smartOrder.buyerCompany ? smartOrder.buyerEmail : undefined,
  );

  const buyerName = useMemo(() => {
    if (smartOrder.buyerCompany) {
      const company = smartOrder.buyerCompany;
      const { name, billingAddress } = company;
      return `${name} - ${billingAddress?.city ?? ''} - ${
        billingAddress?.countryCode?.toLocaleUpperCase() ?? ''
      }`;
    } else if (formattedEmail) {
      return formattedEmail;
    }
    return 'N/A';
  }, [smartOrder.buyerCompany, formattedEmail]);

  return (
    <div
      data-testid="smart-order-card"
      className={clsx(
        'p-3 border border-gray-200 rounded-xl h-auto transition-all duration-500',
        {
          'bg-white':
            smartOrder.isToComplete ||
            smartOrder.hasOpportunities ||
            origin === 'desyncmodal',
          'bg-gray-50':
            !smartOrder.isToComplete &&
            !smartOrder.hasOpportunities &&
            origin !== 'desyncmodal',
          'animate-highlight': highlight,
        },
      )}
    >
      <div className="h-full flex flex-col gap-2 justify-between overflow-hidden">
        <div className="flex flex-col gap-1">
          <div className="text-sm text-gray-900 font-semibold flex gap-2 items-center justify-between">
            <div className="flex items-center justify-between w-full">
              {isDebugModeActive &&
              smartOrder.status === SmartOrderStatus.SENT ? (
                <Link href={`/orders/${smartOrder.orderId}`} target="_blank">
                  #{smartOrder.displayId}
                </Link>
              ) : (
                <span>#{smartOrder.displayId}</span>
              )}
              {smartOrder.acknowledgmentStatus === 'error' && (
                <>
                  <WarningTriangle
                    id={`warning-icon-${smartOrder.id}`}
                    className="h-5 w-5 text-red-700"
                    aria-hidden="true"
                  />
                  <Tooltip
                    anchorSelect={`#warning-icon-${smartOrder.id}`}
                    place="bottom-start"
                    className="z-50"
                  >
                    {ct(
                      'Common.ERPError',
                      "Cette commande n'a pas pu etre transmise a l'ERP",
                    )}
                  </Tooltip>
                </>
              )}
            </div>
          </div>
          <div ref={containerRef} className="w-full">
            <p className="text-sm text-gray-700 flex items-start gap-1 w-full">
              <span ref={emailRef} className="font-semibold">
                {buyerName}
              </span>
            </p>
          </div>
          {/** Dates */}
          <div className="flex items-center justify-between w-full">
            <p className="text-gray-700 text-sm font-semibold">
              {formattedDate(smartOrder.createdAt)}
            </p>
            {smartOrder.assignee && (
              <>
                <span
                  id={`initials-badge-${smartOrder.id}`}
                  className={clsx(
                    'text-xs font-semibold flex items-center justify-center w-6 h-6 rounded-full',
                    smartOrder.assignee.colorClass,
                  )}
                >
                  {smartOrder.assignee.initials}
                </span>
                <Tooltip
                  anchorSelect={`#initials-badge-${smartOrder.id}`}
                  place="bottom-start"
                  className="z-50"
                  style={{ maxWidth: '1000px' }}
                >
                  {smartOrder.assignee.label}
                </Tooltip>
              </>
            )}
          </div>
          {smartOrder.isProcessing && (
            <div className="fade-in-out" style={{ opacity: opacity }}>
              <p className="text-sm font-semibold smart-animate">{message}</p>
            </div>
          )}
          {origin === 'desyncmodal' && (
            <Button
              color="secondary"
              size="sm"
              withoutShadow
              onClick={(e) => {
                e.preventDefault();
                if (
                  smartOrder.smartOrderType ===
                  DraftOrderTypeEnum.PURCHASE_ORDER
                ) {
                  window.open(`/orders/${smartOrder.orderId}`, '_blank');
                } else if (
                  smartOrder.smartOrderType === DraftOrderTypeEnum.QUOTE_REQUEST
                ) {
                  window.open(`${smartOrder.purchaseUrl}`, '_blank');
                }
                trackAmplitudeEvent?.('homepage-desync-alert-order-clicked');
              }}
            >
              {smartOrder.smartOrderType === DraftOrderTypeEnum.PURCHASE_ORDER
                ? ct('Common.SeeOrder', 'Voir la commande')
                : ct('Common.SeeQuote', 'Voir la demande de devis')}
            </Button>
          )}
          {displayErrors && origin === 'kanban' && (
            <SmartOrderError errors={smartOrder.errors} />
          )}
          {displayOpportunities && origin === 'kanban' && (
            <SmartOrderOpportunities />
          )}
        </div>

        {displayCta && (
          <Button
            testId="smart-order-card-cta"
            color="tertiary"
            size="sm"
            withoutShadow
            withBorder
            onClick={(e) => {
              e.stopPropagation(); // prevent card click
              handleClickOnButton(
                smartOrder.status,
                smartOrder.id,
                smartOrder.purchaseUrl,
              );
            }}
          >
            {getCtaLabel(smartOrder.status)}
          </Button>
        )}
        {smartOrder.debugInfo && (
          <SmartOrderDebug
            debugInfo={smartOrder.debugInfo}
            setIsTest={setIsTest}
            isSent={smartOrder.isSent}
            smartOrderId={smartOrder.id}
          ></SmartOrderDebug>
        )}
      </div>
    </div>
  );
};

export default SmartOrderCard;
