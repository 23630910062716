import { Activity, ActivityAgent, ActivityFromApi } from '@/lib/types/activity';
import { UserFromApi } from '../types/user';
import { stringToColour } from './smart-order-builder';

export const buildActivity = (activity: ActivityFromApi): Activity => {
  return {
    id: activity.id,
    type: activity.type,
    createdAt: new Date(activity.created_at),
    draftOrderId: activity.draft_order_id,
    smartOrderDisplayId: activity.draft_order?.display_id ?? null,
    orderId: activity.order_id ?? activity.draft_order?.order_id ?? null,
    agentId: activity.agent_id,
    agent: buildAgent(activity.agent),
    isCanceled: activity.draft_order?.deleted_at !== null,
  };
};

function buildAgent(agent?: UserFromApi | null): ActivityAgent | null {
  if (!agent) {
    return null;
  }
  return {
    email: agent.email,
    fullName: `${agent.first_name} ${agent.last_name}`,
    initials: `${agent.first_name?.[0] || ''}${
      agent.last_name?.[0] || ''
    }`.toUpperCase(),
    colorClass: stringToColour(agent.email),
  };
}
