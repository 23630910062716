'use client';
import React from 'react';
import { Star } from 'iconoir-react';
import { useCatalogTranslation } from '@/lib/hooks/use-catalog-translations';

const SmartOrderOpportunities = () => {
  const { ct } = useCatalogTranslation('admin_orders');
  return (
    <div
      data-testid="smart-order-error"
      className="text-yellow-600 text-xs font-semibold flex items-center gap-1"
    >
      <Star className="w-4 h-4" />
      <span>
        {ct('SmartOrders.opportunitiesDetected', 'Opportunités détectées')}
      </span>
    </div>
  );
};

export default SmartOrderOpportunities;
