'use client';
import React from 'react';
import { useAmplitudeContext } from '@/lib/context/analytics-context';
import Button, { ButtonProps } from '@components/common/button';
import Link from 'next/link';

type TrackLinkProps = ButtonProps & {
  amplitudeEvent: string;
  link: string | null;
};

const TrackLinkButton = ({
  amplitudeEvent,
  link,
  ...props
}: TrackLinkProps) => {
  const { trackAmplitudeEvent } = useAmplitudeContext();
  if (!link) return null;
  return (
    <Link href={link} target="_blank">
      <Button
        type="button"
        {...props}
        onClick={() => trackAmplitudeEvent?.(amplitudeEvent)}
        color="secondary"
      ></Button>
    </Link>
  );
};
export default TrackLinkButton;
