import React from 'react';
import { useTenant } from '@context/tenant-context';
import { useCatalogTranslation } from '@hooks/use-catalog-translations';

const ChartsBlock = () => {
  const { ct } = useCatalogTranslation('admin_homepage');
  const { tenantId } = useTenant();
  const tenantToDisplay = tenantId === 'catalogdemo' ? 'natform' : tenantId;

  const chartUrl = `https://app.hex.tech/590093b6-a45c-4fbd-8a51-8cb8bfa1cd14/app/005fc7cc-20f6-49e0-a86b-a1ec3d4df041/latest?embedded=true&_input_1=%22${tenantToDisplay}%22`;
  return (
    <div className="homepage-card">
      <h2 className="h2">
        {ct('Homepage.charts_title', 'Commandes traitées')}
      </h2>
      <div
        className="hex-embed"
        style={{ height: '550px', width: '100%', minWidth: '800px' }}
      >
        <iframe
          title="SmartOrderCharts"
          src={chartUrl}
          style={{ height: '100%', width: '100%' }}
        ></iframe>
      </div>
    </div>
  );
};

export default ChartsBlock;
