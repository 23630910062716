'use client';

import { useCatalogTranslation } from '@hooks/use-catalog-translations';
import { WarningTriangle, InfoCircle } from 'iconoir-react';
import Button from '@components/common/button';
import DesyncModal from './desync-modal';
import { useState, useMemo } from 'react';
import { useSmartOrders } from '@/lib/hooks/use-smart-orders';
import CustomTooltip from '@components/common/tooltip';
import { useAmplitudeContext } from '@/lib/context/analytics-context';

const SmartOrderAlertBanner = () => {
  const { ct } = useCatalogTranslation('admin_homepage');
  const { smartOrders } = useSmartOrders();
  const [open, setOpen] = useState(false);
  const { trackAmplitudeEvent } = useAmplitudeContext();

  const desyncOrders = useMemo(
    () =>
      smartOrders?.filter((order) => order.acknowledgmentStatus === 'error') ??
      [],
    [smartOrders],
  );

  const handleOpenModal = () => {
    setOpen(true);
  };

  if (!smartOrders) return null;

  return (
    <div className="flex bg-error-50 p-4 rounded-lg gap-4">
      <div className="flex items-center justify-center bg-error-100 rounded-full h-7 w-7 min-w-7">
        <WarningTriangle className="h-5 w-5 text-red-900" aria-hidden="true" />
      </div>
      <div className="items-center gap-2 w-full">
        <p className="flex text-red-900 gap-3">
          <strong>
            {ct(
              'Homepage.smartOrderAlertBanner',
              'Vous avez {{count}} commandes desync',
              {
                count: desyncOrders.length,
              },
            )}
          </strong>
          <CustomTooltip
            content={ct(
              'Homepage.smartOrderAlertBannerTooltip',
              'Accedez à la liste des commandes desynchronisées',
            )}
          >
            <InfoCircle className="h-5 w-5" />
          </CustomTooltip>
        </p>
        <p className="text-red-900">
          {ct(
            'Homepage.smartOrderAlertBannerDescription',
            "Nous n'avons pas reçu d'accusé de réception pour les commandes suivantes :",
          )}
        </p>
      </div>
      <div className="flex items-center">
        <Button
          color="tertiary"
          size="sm"
          withoutShadow
          withBorder
          onClick={() => {
            handleOpenModal();
            trackAmplitudeEvent?.('homepage-desync-alert-clicked');
          }}
        >
          {ct('Homepage.desyncModalSeeDetails', 'Voir les commandes')}
        </Button>
      </div>
      <DesyncModal open={open} setOpen={setOpen} desyncOrders={desyncOrders} />
    </div>
  );
};

export default SmartOrderAlertBanner;
