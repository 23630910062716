'use client';
import React, { useMemo } from 'react';
import { InfoCircle, XmarkCircle, CheckCircle } from 'iconoir-react';
import { SmartOrderDebugInfo } from '@/lib/types/smart-orders';
import { Tooltip } from 'react-tooltip';
import Link from 'next/link';
import clsx from 'clsx';

type SmartOrderDebugProps = {
  debugInfo: SmartOrderDebugInfo;
  setIsTest: (isTest: boolean) => void;
  isSent: boolean;
  smartOrderId: string;
};
const SmartOrderDebug = ({
  debugInfo,
  setIsTest,
  isSent,
  smartOrderId,
}: SmartOrderDebugProps) => {
  const errorsDetails = useMemo(() => {
    if (debugInfo.technicalError) {
      return (
        <>
          <div
            className="processing-failed text-red-500 text-sm flex items-start gap-1"
            id={`processing-failed-${smartOrderId}`}
          >
            <XmarkCircle className="w-6 h-6" />
            <span>Processing failed</span>
          </div>
          <Tooltip
            anchorSelect={`#processing-failed-${smartOrderId}`}
            place="bottom-start"
            className="z-50"
            style={{ maxWidth: '1000px' }}
          >
            {JSON.stringify(debugInfo.technicalError, null, 2)}
          </Tooltip>
        </>
      );
    }
    if (!debugInfo.failureCauses?.length)
      return (
        <div className="text-green-500 text-sm flex items-center gap-1">
          <CheckCircle className="w-6 h-6" />
        </div>
      );
    return (
      <div className="text-orange-500 text-sm flex items-center gap-1">
        <InfoCircle className="w-6 h-6" />
        <div className="flex flex-col">
          {debugInfo.failureCauses?.map((message) => (
            <span key={message}>{message}</span>
          ))}
        </div>
      </div>
    );
  }, [debugInfo]);

  return (
    <>
      <Link href={debugInfo.logsUrl} target="_blank">
        {errorsDetails}
      </Link>
      <div className="text-sm flex items-center gap-1 text-gray-700 cursor-pointer">
        <input
          type="checkbox"
          checked={debugInfo.isTest}
          onChange={() => setIsTest(!debugInfo.isTest)}
        />
        <span>Test order</span>
      </div>
    </>
  );
};

export default SmartOrderDebug;
