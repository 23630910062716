'use client';

import React from 'react';
import HomepageFeedback from './homepage-feedback';
import OnboardingBlock from '../onboarding-block';
import NextStepBlock from '../next-step-block';
import ChartsBlock from '../charts-block';
import SmartOrderAlertBanner from '../../smart-orders/alert-banner/smart-order-alert-banner';
import { useSmartOrders } from '@hooks/use-smart-orders';

const HomeMainLayout = () => {
  const { smartOrders } = useSmartOrders();
  const count = smartOrders?.filter(
    (order) => order.acknowledgmentStatus === 'error',
  ).length;
  return (
    <div className="flex flex-col gap-6 h-full overflow-y-auto">
      {count ? <SmartOrderAlertBanner /> : null}
      <OnboardingBlock />
      <ChartsBlock />
      <NextStepBlock />
      <HomepageFeedback />
    </div>
  );
};

export default HomeMainLayout;
