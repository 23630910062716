import { useEffect, useState } from 'react';

export const useTimeBasedMessage = (
  config: {
    message: string;
    threshold: number;
  }[],
  basedDate: Date,
) => {
  const [timeBasedMessage, setTimeBasedMessage] = useState('');
  const [opacity, setOpacity] = useState(1);

  useEffect(() => {
    const sortedConfig = [...config].sort((a, b) => b.threshold - a.threshold);
    const updateMessage = () => {
      const timeDiff = Date.now() - basedDate.getTime();
      const newMessage =
        sortedConfig.find(({ threshold }) => timeDiff > threshold)?.message ||
        sortedConfig[sortedConfig.length - 1].message;

      if (newMessage !== timeBasedMessage) {
        setOpacity(0);
        setTimeout(() => {
          setTimeBasedMessage(newMessage);

          requestAnimationFrame(() => {
            setOpacity(1);
          });
        }, 500);
      }
    };

    updateMessage();
    const interval = setInterval(updateMessage, 1000);
    return () => clearInterval(interval);
  }, [config, basedDate]);

  return { message: timeBasedMessage, opacity };
};
