import { useCallback, useEffect, useRef, useState } from 'react';

/**
 * Hook to truncate email addresses when they overflow their container
 * Adds ellipsis before the @ symbol while keeping the domain intact
 */
export const useEmailTruncation = (email: string | undefined) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const emailRef = useRef<HTMLSpanElement>(null);
  const [formattedEmail, setFormattedEmail] = useState<string | undefined>(
    email,
  );

  const truncateEmail = useCallback((email?: string) => {
    const emailElement = emailRef.current;
    const container = containerRef.current;
    if (!emailElement || !container || !email) return;

    // Find the position of @ in the email
    const atIndex = email.indexOf('@');

    // Only truncate if email width exceeds container width
    if (emailElement.offsetWidth >= container.offsetWidth) {
      // Start with full local part (before @) and gradually reduce it
      let visibleChars = atIndex;
      while (
        emailElement.offsetWidth >= container.offsetWidth &&
        visibleChars > 0
      ) {
        visibleChars--;
        emailElement.textContent = `${email.slice(
          0,
          visibleChars,
        )}...@${email.slice(atIndex + 1)}`;
      }
      setFormattedEmail(emailElement.textContent!);
    }
  }, []);

  useEffect(() => {
    truncateEmail(email);
    const handleResize = () => truncateEmail(email);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [email]);

  return { containerRef, emailRef, formattedEmail };
};
