'useClient';
import React from 'react';

export type ButtonProps = {
  color: 'primary' | 'alert' | 'success' | 'secondary' | 'tertiary';
  size: 'lg' | 'md' | 'sm' | 'xs';
  children: React.ReactNode;
  testId?: string;
  withBorder?: boolean;
  withoutShadow?: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const Button = ({
  color = 'primary',
  size = 'md',
  children,
  testId,
  withBorder = false,
  withoutShadow = false,
  ...props
}: ButtonProps) => {
  const getSizeClasses = () => {
    switch (size) {
      case 'lg':
        return 'btn-lg';
      case 'md':
        return 'btn-md';
      case 'sm':
        return 'btn-sm';
      case 'xs':
        return 'btn-xs';
    }
  };

  const getColorClasses = () => {
    switch (color) {
      case 'primary':
        return size === 'xs' ? 'btn-text-primary' : 'btn-primary';
      case 'alert':
        return size === 'xs' ? 'btn-text-alert' : 'btn-alert';
      case 'success':
        return size === 'xs' ? 'btn-text-success' : 'btn-success';
      case 'secondary':
        return size === 'xs' ? 'btn-text-secondary' : 'btn-secondary';
      case 'tertiary':
        return size === 'xs' ? 'btn-text-tertiary' : 'btn-tertiary';
      default:
        return '';
    }
  };

  const getBorderClasses = () => {
    if (size === 'xs' || !withBorder) {
      return '';
    }

    switch (color) {
      case 'primary':
        return 'border border-orange-200';
      case 'alert':
        return 'border border-red-200';
      case 'success':
        return 'border border-lime-200';
      case 'secondary':
        return 'border border-stone-400';
      case 'tertiary':
        return 'border border-gray-200';
      default:
        return 'border';
    }
  };

  const getShadowClasses = () => {
    if (withoutShadow) {
      return 'shadow-none focus:ring-0 focus:outline-none focus:ring-offset-0';
    }

    return '';
  };

  const classes = `whitespace-nowrap btn ${getSizeClasses()} ${getColorClasses()} ${getBorderClasses()} ${getShadowClasses()}`;

  return (
    <button className={classes} data-testid={testId} {...props}>
      {children}
    </button>
  );
};

export default Button;
